<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>基础设置</el-breadcrumb-item>
            <el-breadcrumb-item>榜单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
                <el-button type="primary" size="small" @click="showAdd(1)">添加榜单</el-button>
            </div>
            <!--------------------用户表格--------------------->
            <!--            级别、价格-->
            <el-table :data="assistantList" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="达人名称">
                    <template slot-scope="scope">
                        <span>{{ scope.row.assistant.nickname }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cover" label="头像">
                    <template slot-scope="scope">
                        <el-image style="width: 112px;height: 80px;object-fit: cover;" :src="scope.row.assistant.avatar" :preview-src-list="[scope.row.assistant.avatar]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="sn" label="排序值"></el-table-column>
                <el-table-column prop="" label="操作" >
                    <template slot-scope="scope" >
                        <el-button type="text" size="small" @click="editItem(scope.row)">编辑</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" size="small" @click="removeItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--添加对话框-->
            <el-dialog :title="isEdit ? '编辑榜单' : '添加榜单'" :visible.sync="addDialog" width="592px"  append-to-body  @close="closeDialog">
                <div class="dialog-content">
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
                        <el-form-item label="商家：" prop="res_id" v-if="addType == 0">
                            <el-select v-model="form.res_id" size="small" :disabled="isEdit" filterable>
                                <el-option v-for="(item, index) in arenaArr" :key="index" :label="item.fullname" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="达人：" prop="res_id"  v-if="addType == 1">
                            <el-select v-model="form.res_id" size="small"  :disabled="isEdit" filterable>
                                <el-option v-for="(item, index) in assistantArr" :key="index" :label="item.nickname" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="排序值：" prop="sn">
                            <el-input-number size="small" v-model="form.sn" :min="1" :max="99999" class="num"></el-input-number>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addDialog = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">保 存</el-button>
            </span>
            </el-dialog>

        </el-card>
    </div>
</template>

<script>
export default {
    name: "list",

    data() {
        return {
            activeName: 'assistant',//tab类型

            arenaList: [],//商家列表
            assistantList: [],//达人列表
            addDialog: false,//添加弹窗
            form: {//表单提交内容
                res_id: '',
                sn: 1,
            },
            rules: {// 表单校验规则对象
                res_id: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                sn: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
            },
            btnLoading: false,//按钮状态
            isEdit: false,//是否编辑
            addType: 1,//0球館 1达人
            arenaArr: [],//商家选项
            assistantArr: [],//达人选项
            loading: false,//表格加载loading
        }
    },
    mounted () {
        this.getList()//查列表
        //this.getArena();//查商家
        this.getAssistant();//查达人
    },
    methods: {
        //查列表
        async getList() {
            this.loading = true;
            let { data: res } = await this.$http.get('/admin/api/recomm/typeList',)
            this.loading = false;
            if (res.code == 200) {
                if(res.data) {
                    if(this.activeName == 'arena') {
                        this.arenaList = res.data.arena
                    } else {
                        this.assistantList = res.data.assistant
                    }
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //查商家
        async getArena() {
            let { data: res } = await this.$http.get('/admin/api/arena/list',)
            if (res.code == 200) {
                if(res.data) {
                    this.arenaArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //查达人
        async getAssistant() {
            let { data: res } = await this.$http.get('/admin/api/assistant/list',)
            if (res.code == 200) {
                if(res.data) {
                    this.assistantArr = res.data
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //切换tab
        changeTab(tab, event) {
            if(tab.name == 'arena') {
                this.addType = 0
            } else {
                this.addType = 1
            }
            this.getList();
        },
        //打开添加弹窗
        showAdd(type) {
            this.addType = type
            this.addDialog = true
        },
        //添加/编辑
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let param = {
                        type: this.addType == 0 ? 'arena' : 'assistant',
                        sn: this.form.sn,
                        res_id: this.form.res_id,
                    }
                    let res = {};
                    if (this.isEdit) {
                        param.id = this.form.id;
                        res = await this.$http.post('/admin/api/recomm/update', param)
                    } else {
                        res = await this.$http.post('/admin/api/recomm/add', param)
                    }
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeDialog();
                        this.form = {//名称
                            res_id: '',
                            sn: 1,
                        };
                        await this.getList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeDialog() {
            if (this.form.id) {
                delete this.form.id;
            }
            this.isEdit = false;
            this.btnLoading = false;
            this.$refs['ruleForm'].resetFields();
            this.addDialog = false;
        },
        //编辑
        async editItem(rows) {
            this.isEdit = true;
            this.$nextTick(() => {
                this.form.id = rows.id;
                this.form.sn = rows.sn;
                this.form.res_id = rows.res_id;
            })

            this.addDialog = true; //打开对话框
        },
        //删除
        removeItem(rows) {
            let name = '';
            if(this.addType == 0) {
                name = rows.arena.fullname;
            } else {
                name = rows.assistant.nickname;
            }

            this.$confirm('此操作将永久删除', '确认要删除“' + name + '”的排序吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let res = await this.$http.post('/admin/api/recomm/delete', {id: rows.id})
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.getList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
    }
}
</script>

<style scoped>

</style>
